@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,800;1,400;1,600;1,800&display=swap');




body {
	height: 100%;
	background-color: #edf2f9;
	padding-top: 70px;
	font-family: Poppins,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
	font-size: 14px

}

.navbar {
	background-color: #edf2f9;
}

li.nav-item {

		padding-top: 6px !important;

		.nav-link {
			color: #000000;
		}
		.nav-link:hover {
			color: #0000ff;
		}
	}

h2.page-header {
	background: #ffffff;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    box-shadow: 0 .0rem 1rem rgba(0,0,0,.15)!important;

}

.table-responsive {
	overflow-y: auto;
	background-color: #ffffff;
	thead {background-color:#ffffff}

	table thead th { position: sticky; top: -1px;background-color:#ffffff; }
} 

.btn-white {
	background-color: #ffffff;
	margin-right: 6px;
}

.form-inline .form-check {
	align-items: flex-start;
	justify-content: flex-start;
}

table.detail-table {
	tbody {
		tr {
			td:first-child {
				font-weight: 600;
			}	 

		}

	}

}

.dropdown-menu-right {
	right: -32px !important;
	left: -120px !important;
	transform: translate3d(-31px, 38px, 0px) !important;
}
